import ResizeObserver from 'resize-observer-polyfill';

class PercentageViewed {
  // отслеживаемый элемент - по умолчанию body
  element: HTMLElement;
  // процент просмотра
  percentage: number = 0;
  // resizeObserver для элемента
  ro: ResizeObserver | null = null;
  //
  calcBind: any = this.calc.bind(this);

  constructor (element: HTMLElement = document.body) {
    this.element = element;
    this.init();
  }

  init () {
    window.addEventListener('resize', this.calcBind);
    window.addEventListener('scroll', this.calcBind);
    this.ro = new ResizeObserver(() => this.calc());
    this.ro.observe(this.element);
  }

  destroy () {
    window.removeEventListener('resize', this.calcBind);
    window.removeEventListener('scroll', this.calcBind);
    this.ro?.unobserve(this.element);
    this.percentage = 0;
    this.ro = null;
  }

  calc () {
    // если элемент - это body
    if (document.body === this.element) {
      this.percentage = Math.round(
        (this.calcWindowScroll() / this.calcDocumentBodyHeight()) * 100,
      );
      return;
    }

    // если кастомный элемент
    const elementOffsets = this.element.getBoundingClientRect();
    const elementHeight = this.element.offsetHeight;

    // координаты верхней границы
    const topOffsetByDocument = elementOffsets.top + pageYOffset;
    // координаты нижней границы
    const bottomOffsetByDocument = topOffsetByDocument + elementHeight;

    // если элемент находится ниже просматриваемой области
    if (this.calcWindowScroll() < topOffsetByDocument) {
      this.percentage = 0;
    // если элемент выше просматриваемой области
    // считаем что он просмотрен на 100%
    } else if (this.calcWindowScroll() > bottomOffsetByDocument) {
      this.percentage = 100;
    // в противном случае считаем
    } else {
      this.percentage = Math.round(((this.calcWindowScroll() - topOffsetByDocument) / elementHeight) * 100);
    }
  }

  // высота документа
  calcDocumentBodyHeight () {
    return Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight,
    );
  }

  // сколько просмотрено от документа:
  // скролл + высота экрана
  calcWindowScroll () {
    return scrollY + document.documentElement.clientHeight;
  }

  get viewed () {
    return this.percentage;
  }

  set viewed (value) {
    this.percentage = value;
  }
}

export default PercentageViewed;
