// выходит ли элемент выходит за границы окна

type TPositionElement = 'bottom' | 'top' | 'left' | 'right' | null;

export function isElementBeyondWindow (element: HTMLElement, side: string = '', offset = 0) {
  const rectElement = element.getBoundingClientRect();

  type TBooleanProperty = {
    [key: string]: boolean
  };

  const beyondBySide: TBooleanProperty = {
    top: rectElement.top < offset,
    right: rectElement.right > window.innerWidth - offset,
    bottom: rectElement.bottom > window.innerHeight - offset,
    left: rectElement.left < offset,
  };

  const has = Object.prototype.hasOwnProperty;

  return side && has.call(beyondBySide, side)
    ? beyondBySide[side]
    : Object.keys(beyondBySide).some(sideBeyond => sideBeyond);
};

export function isElementBeyondPage (
  element: HTMLElement, side: TPositionElement = null, offset = 0,
) {
  const {
    top, right, left, bottom,
  } = element.getBoundingClientRect();

  type TBooleanProperty = {
    [key: string]: boolean;
  };

  const beyondBySide: TBooleanProperty = {
    top: top + window.pageYOffset <= offset,
    right: right + window.pageXOffset >= document.documentElement.scrollWidth - offset,
    bottom: bottom + window.pageYOffset >= document.documentElement.scrollHeight - offset,
    left: left + window.pageXOffset <= offset,
  };

  return side && Object.prototype.hasOwnProperty.call(beyondBySide, side)
    ? beyondBySide[side]
    : Object.keys(beyondBySide).some(sideBeyond => sideBeyond);
}
