import getSlugFromUrl from './getSlugFromUrl';
import { IAnalyticsEventsData } from '~/types/analytics/ga-4';
import { YandexMetrikData } from '~/types/analytics/yandexMetrika';
import { snowplowAnalytics } from '~/types/analytics/snowplow';
import { isAmpPage } from '~/router';

export const sendCustomAnalytics = function (id: string, AnalyticsEventsData: Record<string, any>): void {
  if (typeof window === 'undefined') { return; }

  // @ts-ignore
  window[id]?.push(AnalyticsEventsData);
};

export const sendAnalytics = function (id: string, AnalyticsEventsData: IAnalyticsEventsData): void {
  const userId = window?.$nuxt?.$store?.state?.user?.integer_id || 0;

  sendCustomAnalytics(id, { ...AnalyticsEventsData, userId });
};

export const sendAnalyticsSnowPlow = function (analyticsParams: snowplowAnalytics): void {
  if (typeof window === 'undefined') {
    return;
  }

  const { event_name, par1, par2, par3, par4 = '', par5 = '' } = analyticsParams;

  const location = par1 ?? getSlugFromUrl(window?.$nuxt?.$route?.fullPath) ?? '';
  const userId = par2 ?? window?.$nuxt?.$store?.state?.user?.integer_id ?? 'guest';

  // eslint-disable-next-line
  const analyticsData = {
    event_name,
    par1: location,
    par2: userId,
    par3,
    par4,
    par5,
  };

  // Временно отключено https://app.clickup.com/t/86bz98ne5
  // trackSelfDescribingEvent({
  //   event: {
  //     schema: 'iglu:dev.snowplow.simple/custom_data/jsonschema/1-0-0',
  //     data: analyticsData,
  //   },
  // });
};

export const sendCustomYandexMetrika = (id: number, params: object): void => {
  window.ym(id, 'params', params);
};

export const sendYandexMetrika = function (id: number, analyticsParams: YandexMetrikData): void {
  if (typeof window === 'undefined') {
    return;
  }

  const { level1, level2, level3, level4 = '', level5 = '', level6 = '', level7 = '', level8 = '' } = analyticsParams;

  const userId = level3 ?? window?.$nuxt?.$store?.state?.user?.integer_id ?? 'guest';
  const location = level2 ?? getSlugFromUrl(window?.$nuxt?.$route?.fullPath) ?? '';
  const isAmp = level7 ?? isAmpPage(window?.$nuxt?.$route?.name) ? 'AMP' : 'Regular';

  const analyticsData = {
    [level1]:
    {
      [location]:
      {
        [userId]:
        {
          [level4]:
          {
            [level5]:
            {
              [level6]:
              {
                [isAmp]:
                [level8],
              },
            },
          },
        },
      },
    },
  };

  sendCustomYandexMetrika(id, analyticsData);
};
